import * as React from 'react';

import { createLogger } from '@/modules/logging/logger';
import { Layout } from './Layout';

interface ErrorPageProps {
  message?: string;
  header?: string;
}
export const ErrorPage: React.FunctionComponent<ErrorPageProps> = ({ message, header }) => {
  return (
    <Layout>
      <div className="vh-100 dt center">
        <div className="v-mid dtc">{message ? message : `Something went wrong. Please refresh and try again`}</div>
      </div>
    </Layout>
  );
};
