import * as React from 'react';
import { NextPage } from 'next';
import { Event } from '@/components/Event';
import { Layout } from '@/components/Layout';
import { googleStructuralData } from '@/lib/index';
import { EventProps } from '@/modules/data/types/Event';
import { createLogger } from '@/modules/logging/logger';
import { Db } from 'mongodb';
import { eventCollection } from '../api/helpers';
const connect = require('../api/db');

import * as Sentry from '@sentry/node';
import { ParsedUrlQuery } from 'querystring';
import useEvent from '@/modules/data/types/hooks/useEvent';
import { useRouter } from 'next/router';
import { ErrorPage } from '@/components/Error';
import { Spinner } from '@/components/Spinner';
import { SSRPageProps } from 'src/app/types/SSRPageProps';
import { AMPLITUDE_PAGES } from '@/modules/amplitude/events';

let logger = createLogger({
  fileLabel: '/pages/e/[slug]',
});
interface EventSearchProps {
  eventData: EventProps;
}
// interface IParams extends ParsedUrlQuery {
//   slug: string;
// }
/**
 * Props that are only available for this page
 */
type CustomPageProps = {};

// type GetServerSidePageProps = CustomPageProps & SSRPageProps;

/**
 * SSR pages are first rendered by the server
 * Then, they're rendered by the client, and gain additional props (defined in OnlyBrowserPageProps)
 * Because this last case is the most common (server bundle only happens during development stage), we consider it a default
 * To represent this behaviour, we use the native Partial TS keyword to make all OnlyBrowserPageProps optional
 *
 * Beware props in OnlyBrowserPageProps are not available on the server
 */

export async function getServerSideProps({ params: { slug } }: { params: { slug: string } }) {
  // Fetch data from external API
  try {
    const db: Db = await connect();
    const eventData = (await db.collection(eventCollection).find({ slug: slug.toLocaleLowerCase() }).toArray())?.[0];

    return {
      props: {
        eventData: JSON.parse(JSON.stringify(eventData)),
      },
    };
  } catch (e) {
    logger.error(e);
    Sentry.captureException(e);
  }
}

const Page: NextPage<EventSearchProps> = (props) => {
  const { eventData } = props;
  const { query } = useRouter();
  const { slug } = query;
  const { event, isError, isLoading } = useEvent(slug?.toString().toLocaleLowerCase(), eventData);
  if (isError) return <ErrorPage />;
  if (isLoading) return <Spinner />;
  // logger.info(slug, event);
  const site = {
    title: `${event.name} Tickets`,
    header: 'TBA',
    description: 'Discover lit events.',
    image: event.imageWide || event.image,
  };
  return (
    <Layout data={site} noPadding pageName={AMPLITUDE_PAGES.EVENT_PAGE}>
      <div className="content" dangerouslySetInnerHTML={{ __html: googleStructuralData(event) }} />
      <div className="overflow-x-hidden">
        <Event event={event} />
      </div>
    </Layout>
  );
};

export default Page;
