import useRequest from '@/lib/useRequest';
import { EventProps } from '../Event';

const useEvent = (slug, initialData) => {
	const { data, error } = useRequest<EventProps, any>(
		{
			url: `/api/event/${slug}`,
		},
		{ initialData }
	);

	return {
		event: data,
		isLoading: !error && !data,
		isError: error,
	};
};
export default useEvent;
