import useSWR, { ConfigInterface, responseInterface, cache } from 'swr';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import nookies from 'nookies';

import { useAuth } from '@/modules/firebase/provider';
import UniversalCookiesManager from '@/modules/cookiesManager/UniversalCookiesManager';
import { createLogger } from '@/modules/logging/logger';

export type GetRequest = AxiosRequestConfig | null;

const logger = createLogger({
  fileLabel: `src/lib/useRequest`,
});
interface Return<Data, Error>
  extends Pick<
    responseInterface<AxiosResponse<Data>, AxiosError<Error>>,
    'isValidating' | 'revalidate' | 'error' | 'mutate'
  > {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<ConfigInterface<AxiosResponse<Data>, AxiosError<Error>>, 'initialData'> {
  initialData?: Data;
}
function fetcher(key) {
  return cache.get(key);
}
async function refreshToken() {
  const { setUser, user } = useAuth();
  const cookiesManager: UniversalCookiesManager = new UniversalCookiesManager();
  cookiesManager.replaceUserData({
    deviceId: user.uid,
    id: user.email,
  });
  const token = user.getIdToken();
  setUser(user);
  nookies.set(undefined, 'token', await token, { path: '/' });
}
export default function useRequest<Data = unknown, Error = unknown>(
  request: GetRequest,
  { initialData, ...config }: Config<Data, Error> = {},
): Return<Data, Error> {
  const {
    data: response,
    error,
    isValidating,
    revalidate,
    mutate,
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    request && JSON.stringify(request),
    /**
     * NOTE: Typescript thinks `request` can be `null` here, but the fetcher
     * function is actually only called by `useSWR` when it isn't.
     */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => axios(request!),
    {
      ...config,
      initialData: initialData && {
        status: 200,
        statusText: 'InitialData',
        config: request,
        headers: {},
        data: initialData,
      },
    },
  );
  if (error?.response?.status === 401) {
    refreshToken();
  }
  return {
    data: (response && response.data) || fetcher(JSON.stringify(request))?.data,
    response,
    error: fetcher(JSON.stringify(request))?.data ? null : error,
    isValidating,
    revalidate,
    mutate,
  };
}
